import AsyncComponent from './AsyncComponent'

const Org = AsyncComponent(() =>
  import(/* webpackChunkName: 'setting-org' */ '../@settings/Organization')
)
const OrgManage = AsyncComponent(() =>
  import(/* webpackChunkName: 'setting-org-manage' */ '../@settings/Organization/Manage')
)
const OrgDustbin = AsyncComponent(() =>
  import(/* webpackChunkName: 'setting-org-dustbin' */ '../@settings/OrgDustbin')
)
const Employee = AsyncComponent(() =>
  import(/* webpackChunkName: 'setting-employee' */ '../@settings/Employee')
)
const EmployeeEdit = AsyncComponent(() =>
  import(/* webpackChunkName: 'setting-employee-edit' */ '../@settings/Employee/Edit')
)
const EmployeeList = AsyncComponent(() =>
  import(/* webpackChunkName: 'setting-employee-list' */ '../@settings/EmployeeList')
)
const Users = AsyncComponent(() =>
  import(/* webpackChunkName: 'setting-user-list' */ '../@settings/Users')
)
const Dictionary = AsyncComponent(() =>
  import(/* webpackChunkName: 'setting-dictionary' */ '../@settings/Dictionary')
)
const DictionaryIndex = AsyncComponent(() =>
  import(/* webpackChunkName: 'setting-dictionary-index' */ '../@settings/DictionaryIndex')
)
const DictionarySearch = AsyncComponent(() =>
  import(/* webpackChunkName: 'setting-dictionary-search' */ '../@settings/DictionarySearch')
)
const CommonPosition = AsyncComponent(() =>
  import(/* webpackChunkName: 'setting-common-position' */ '../@settings/CommonPosition')
)
const Menu = AsyncComponent(() =>
  import(/* webpackChunkName: 'setting-menu' */ '../@settings/MenuSetting')
)
const EnterpriseWechat = AsyncComponent(() =>
  import(/* webpackChunkName: 'setting-enterprise-wechat' */ '../@settings/EnterpriseWechat')
)
const EWApps = AsyncComponent(() =>
  import(/* webpackChunkName: 'setting-ew-apps' */ '../@settings/EWApps')
)
const TaskType = AsyncComponent(() =>
  import(/* webpackChunkName: 'setting-task-type' */ '../@settings/TaskType')
)
const DataSync = AsyncComponent(() =>
  import(/* webpackChunkName: 'setting-data-sync' */ '../@settings/DataSync')
)
const SchedulerJob = AsyncComponent(() =>
  import(/* webpackChunkName: 'setting-scheduler-job' */ '../@settings/SchedulerJob')
)
const SystemParams = AsyncComponent(() =>
  import(/* webpackChunkName: 'setting-system-params' */ '../@settings/SystemParams')
)
const User = AsyncComponent(() =>
  import(/* webpackChunkName: 'setting-user' */ '../@settings/User/index')
)
const SetAuth = AsyncComponent(() =>
  import(/* webpackChunkName: 'setting-auth' */ '../@settings/SetAuth')
)
const PersonalFile = AsyncComponent(() =>
  import(/* webpackChunkName: 'setting-personal-file' */ '../@settings/PersonalFile')
)
const ActionLog = AsyncComponent(() =>
  import(/* webpackChunkName: 'setting-action-log' */ '../@settings/ActionLog')
)

export default [
  {
    path: '/setting/employee/:id/edit',
    component: EmployeeEdit,
    title: ['创建员工档案', '员工档案编辑'],
    paramsKey: 'id',
    exact: true,
    menuBelong: '/setting/employee',
  },
  {
    path: '/setting/personal-file',
    component: PersonalFile,
    title: '我的档案',
    exact: true,
  },
  {
    path: '/setting/employee/:id(\\d+)',
    component: Employee,
    title: '员工档案',
    paramsKey: 'id',
    exact: true,
    menuBelong: '/setting/employee',
  },
  {
    path: '/setting/employee',
    component: EmployeeList,
    title: '员工档案列表',
    exact: true,
    menuBelong: '/setting/employee',
  },
  {
    path: '/setting/users',
    component: Users,
    title: '用户列表',
    exact: true,
    menuBelong: '/setting/users',
  },
  {
    path: '/setting/user',
    component: User,
    title: '个人中心',
    exact: true,
    // menuBelong: '/setting/user',
  },
  {
    path: '/setting/dictionary/search',
    component: DictionarySearch,
    title: '数据字典检索',
    exact: true,
    menuBelong: '/setting/dictionary',
  },
  {
    path: '/setting/dictionary/:id',
    component: DictionaryIndex,
    title: ['新建数据字典', '数据字典编辑'],
    paramsKey: 'id',
    exact: true,
    menuBelong: '/setting/dictionary',
  },
  {
    path: '/setting/dictionary',
    component: Dictionary,
    title: '数据字典',
    exact: true,
    menuBelong: '/setting/dictionary',
  },
  {
    path: '/setting/org/dustbin',
    component: OrgDustbin,
    title: '组织结构回收站',
    exact: true,
    menuBelong: '/setting/org',
  },
  {
    path: '/setting/org',
    component: Org,
    title: '组织结构',
    exact: true,
    menuBelong: '/setting/org',
  },
  {
    path: '/setting/org/manage',
    component: OrgManage,
    title: '组织结构',
    exact: true,
    menuBelong: '/setting/org/manage',
  },
  {
    path: '/setting/position/common',
    component: CommonPosition,
    title: '通用岗位',
    exact: true,
    menuBelong: '/setting/position/common',
  },
  {
    path: '/setting/menu',
    component: Menu,
    title: '菜单设置',
    exact: true,
    menuBelong: '/setting/menu',
    hideSidebar: true,
  },
  {
    path: '/setting/enterprisewechat',
    component: EnterpriseWechat,
    title: '企业微信设置',
    exact: true,
    menuBelong: '/setting/enterprisewechat',
  },
  {
    path: '/setting/enterprisewechat/apps',
    component: EWApps,
    title: '企业微信应用列表',
    exact: true,
    menuBelong: '/setting/enterprisewechat',
  },
  {
    path: '/setting/tasktype',
    component: TaskType,
    title: '任务类型',
    exact: true,
    menuBelong: '/setting/tasktype',
  },
  {
    path: '/setting/datasnyc',
    component: DataSync,
    title: '数据同步',
    exact: true,
    // menuBelong: '/setting/tasktype',
  },
  {
    path: '/setting/system/params',
    component: SystemParams,
    title: '系统参数设置',
    exact: true,
    // menuBelong: '/setting/tasktype',
  },
  {
    path: '/setting/schedulerJob',
    component: SchedulerJob,
    title: '定时任务',
    exact: true,
    menuBelong: '/setting/schedulerJob',
  },
  {
    path: '/setting/auth',
    component: SetAuth,
    title: '权限管理',
    exact: true,
    menuBelong: '/setting/auth',
  },
  {
    path: '/setting/action/log',
    component: ActionLog,
    title: '操作日志',
    exact: true,
    menuBelong: '/setting/action/log',
  },
]
