import { Constant } from '../actions/flowAction'

const initialState = {
  layouts: [],
  fields: [], // 当前主表字段
  subTables: [],
  subFields: [],
  define: {}, // 当前流程
  readonly: false, // 当前流程是否只读
  flowNodes: [], // 流程图节点
  flowVectors: [], // 流程图节点间连线
  flowCenter: 300, // 流程图中心点
  flowKeyBase: 0, // 流程图节点KEY起始编号
  targetIndex: -1, // 流程图选中节点索引
  targetNode: null, // 流程图上节点信息 SNode
  targetForm: null, // 真实流程节点信息
  targetLines: [], // 节点流出的线
  operateType: -1, // 流程下一环节审批人类型（字段、组织、岗位）
  operateFields: [], // 字段审批类型时所选字段
}

export function flowReducer(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case Constant.CLEAR_FLOW_STATE:
      return { ...initialState }
    case Constant.UPDATE_LAYOUTS:
      return { ...state, layouts: payload }
    case Constant.UPDATE_MAIN_FIELDS:
      return { ...state, fields: payload }
    case Constant.UPDATE_SUB_TABLES:
      return { ...state, subTables: payload }
    case Constant.UPDATE_SUB_FIELDS:
      return { ...state, subFields: payload }
    case Constant.UPDATE_TARGET_INDEX:
      return { ...state, targetIndex: payload }
    case Constant.UPDATE_TARGET_NODE:
      return { ...state, targetNode: payload }
    case Constant.UPDATE_TARGET_NODE_FORM:
      return { ...state, targetForm: payload }
    case Constant.UPDATE_TARGET_LINES:
      return { ...state, targetLines: payload }
    case Constant.UPDATE_NODE_OPERATE_TYPE:
      return { ...state, operateType: payload }
    case Constant.UPDATE_NODE_OPERATE_FIELDS:
      return { ...state, operateFields: payload }
    case Constant.UPDATE_FLOW_DEFINE:
      return { ...state, define: payload }
    case Constant.UPDATE_FLOW_READONLY:
      return { ...state, readonly: payload }
    case Constant.UPDATE_FLOW_CANVAS_NODES:
      return { ...state, flowNodes: payload }
    case Constant.UPDATE_FLOW_CANVAS_VECTORS:
      return { ...state, flowVectors: payload }
    case Constant.UPDATE_FLOW_CANVAS_CENTER:
      return { ...state, center: payload }
    case Constant.UPDATE_FLOW_KEY_BASE:
      return { ...state, flowKeyBase: payload }
    default:
      break
  }
  return state
}
