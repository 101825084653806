import { Constant } from '../actions/orgSelectAction'

const initialState = {
  type: 1,
  multiple: true,
  opened: [],
  selected: [],
  showUnreal: false,
}

export function orgSelectReducer(state = { ...initialState }, action) {
  const { type, payload } = action
  switch (type) {
    case Constant.UPDATE_MULTIPLE:
      return { ...state, multiple: payload }
    case Constant.UPDATE_SELECT_TYPE:
      return { ...state, type: payload }
    case Constant.UPDATE_OPENED:
      return { ...state, opened: payload }
    case Constant.UPDATE_SELECTED:
      return { ...state, selected: payload }
    case Constant.UPDATE_SHOWUNREAL:
      return { ...state, showUnreal: payload }
    case Constant.INIT_STATE:
      return { ...initialState }
    default:
      break
  }
  return state
}
