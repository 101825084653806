import React from 'react'

import styles from './ErrorPage.module.less'

import commonEnv from '../../../constants/env'

const { REACT_APP_403 } = commonEnv

interface Props {
  message?: React.ReactNode
  back?: string
  backText?: string
}

export default function Forbidden({
  message = '您没有访问权限',
  back = '/',
  backText = '返回首页',
}: Props) {
  return (
    <div className={styles['exception-page']}>
      <div className={styles['ep-body']}>
        <img src={REACT_APP_403} alt="403" />
        <h3>{message}</h3>
        <p>
          <a href={back} className="btn btn-primary btn-sm">
            {backText}
          </a>
        </p>
      </div>
    </div>
  )
}
