export const Constant = {
  UPDATE_MULTIPLE: '@orgSelect/UPDATE_MULTIPLE',
  UPDATE_SELECT_TYPE: '@orgSelect/UPDATE_SELECT_TYPE',
  UPDATE_OPENED: '@orgSelect/UPDATE_OPENED',
  UPDATE_SELECTED: '@orgSelect/UPDATE_SELECTED',
  INIT_STATE: '@orgSelect/INIT_STATE',
  UPDATE_SHOWUNREAL: '@orgSelect/UPDATE_SHOWUNREAL',
}

// 初始化组织结构选择全部状态
export const initOrgState = () => ({
  type: Constant.INIT_STATE,
})

// 更新当前组织结构选择的复选单选状态
export const updateMultipleSelect = payload => ({
  type: Constant.UPDATE_MULTIPLE,
  payload,
})

// 更新当前组织结构选择的选择类型（用户/组织）
export const updateSelectType = payload => ({
  type: Constant.UPDATE_SELECT_TYPE,
  payload,
})

// 更新当前组织结构展开的节点
export const updateNodeOpened = payload => ({
  type: Constant.UPDATE_OPENED,
  payload,
})

// 更新当前组织结构选中的节点
export const updateNodeSelected = payload => ({
  type: Constant.UPDATE_SELECTED,
  payload,
})

// 更新是否隐藏虚拟岗位
export const updateShowUnreal = payload => ({
  type: Constant.UPDATE_SHOWUNREAL,
  payload,
})
