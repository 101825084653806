import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { renderRoutes } from 'react-router-config'
import { Provider } from 'react-redux'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'

import 'antd/es/grid/style/css'

import createStore from './store'
import GlobalContext, { useCurrentUser } from './context/GlobalContext'

import routes from './routes'
import Main from './layout/Main'

import commonEnv from '../constants/env'

import './styles/main.less'
import './styles/antd.override.less'
import { QueryClient, QueryClientProvider } from 'react-query'

const { REACT_APP_COMPANY_NAME } = commonEnv

dayjs.locale('zh-cn')

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      refetchInterval: false,
    },
  },
})

function AppRoutes() {
  const [currentUser, setCurrentUser] = useCurrentUser()
  // 未读消息数量
  const [unReadMessageCount, setUnReadMessageCount] = React.useState(0)
  // 收藏列表
  const [favList, setFavList] = React.useState<UserCollectVO[]>([])
  // 权限列表
  const [rights, setRights] = React.useState<any[]>([])
  const [rightsFetched, setRightsFetched] = React.useState(false)
  // 菜单
  const [menuList, setMenuList] = React.useState<any[]>([])
  const [authData, setAuthData] = React.useState({})
  const [authFetched, setAuthFetched] = React.useState(false)

  return (
    <Router>
      <GlobalContext.Provider
        value={{
          unReadMessageCount,
          setUnReadMessageCount,
          favList,
          setFavList,
          rights,
          setRights,
          rightsFetched,
          setRightsFetched,
          menuList,
          setMenuList,
          authData,
          setAuthData,
          authFetched,
          setAuthFetched,
          currentUser,
          setCurrentUser,
        }}
      >
        <Main routes={routes}>{renderRoutes(routes, { company: REACT_APP_COMPANY_NAME })}</Main>
      </GlobalContext.Provider>
    </Router>
  )
}

export default function PcApp() {
  const store = createStore({}) as any

  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider locale={zhCN}>
        <Provider store={store}>
          <AppRoutes />
        </Provider>
      </ConfigProvider>
    </QueryClientProvider>
  )
}
