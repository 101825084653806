import AsyncComponent from './AsyncComponent'

const UserFileLibrary = AsyncComponent(() =>
  import(/* webpackChunkName: 'filesystem-user' */ '../@filesystem/UserFileLibrary')
)
const FileTag = AsyncComponent(() =>
  import(/* webpackChunkName: 'filesystem-file-tag' */ '../@filesystem/FileTag')
)
const CompanyFileLibrary = AsyncComponent(() =>
  import(/* webpackChunkName: 'filesystem-company' */ '../@filesystem/CompanyFileLibrary')
)
const ProjectFileLibrary = AsyncComponent(() =>
  import(/* webpackChunkName: 'filesystem-project' */ '../@filesystem/ProjectFileLibrary')
)

export default [
  {
    path: '/filesystem/personal',
    component: UserFileLibrary,
    title: '个人文件库',
    exact: true,
    hideSidebar: true,
  },
  {
    path: '/filesystem/fileTag',
    component: FileTag,
    title: '标签管理',
    exact: true,
  },
  {
    path: '/filesystem/company',
    component: CompanyFileLibrary,
    title: '公司文件库',
    exact: true,
    hideSidebar: true,
  },
  {
    path: '/filesystem/project',
    component: ProjectFileLibrary,
    title: '项目文件库',
    exact: true,
    hideSidebar: true,
  },
]
