export const Constant = {
  CLEAR_FLOW_STATE: 'flow/CLEAR_FLOW_STAE',
  UPDATE_LAYOUTS: 'flow/UPDATE_LAYOUTS',
  UPDATE_MAIN_FIELDS: 'flow/UPDATE_MAIN_FIELDS',
  UPDATE_SUB_TABLES: 'flow/UPDATE_SUB_TABLES',
  UPDATE_SUB_FIELDS: 'flow/UPDATE_SUB_FIELDS',

  UPDATE_TARGET_INDEX: 'flow/UPDATE_TARGET_INDEX',
  UPDATE_TARGET_NODE: 'flow/UPDATE_TARGET_NODE',
  UPDATE_TARGET_NODE_FORM: 'flow/UPDATE_TARGET_NODE_FORM',
  UPDATE_TARGET_LINES: 'flow/UPDATE_TARGET_LINES',
  UPDATE_NODE_OPERATE_TYPE: 'flow/UPDATE_NODE_OPERATE_TYPE',
  UPDATE_NODE_OPERATE_FIELDS: 'flow/UPDATE_NODE_OPERATE_FIELDS',

  UPDATE_FLOW_DEFINE: 'flow/UPDATE_FLOW_DEFINE',
  UPDATE_FLOW_READONLY: 'flow/UPDATE_FLOW_READONLY',

  UPDATE_FLOW_CANVAS_NODES: 'flow/UPDATE_FLOW_CANVAS_NODES',
  UPDATE_FLOW_CANVAS_VECTORS: 'flow/UPDATE_FLOW_CANVAS_VECTORS',
  UPDATE_FLOW_CANVAS_CENTER: 'flow/UPDATE_FLOW_CANVAS_CENTER',
  UPDATE_FLOW_KEY_BASE: 'flow/UPDATE_FLOW_KEY_BASE',
}

// 更新当前流程所有布局
export const clearFlowState = () => ({
  type: Constant.CLEAR_FLOW_STATE,
})

// 更新当前流程所有布局
export const updateLayouts = layouts => ({
  type: Constant.UPDATE_LAYOUTS,
  payload: layouts,
})

// 更新当前主表的所有字段
export const updateMainTableFields = fields => ({
  type: Constant.UPDATE_MAIN_FIELDS,
  payload: fields,
})

// 更新当前流程下所有明细表
export const updateSubTableList = tables => ({
  type: Constant.UPDATE_SUB_TABLES,
  payload: tables,
})

// 更新当前正在编辑的明细表字段
export const updateCurrentSubTableFields = fields => ({
  type: Constant.UPDATE_SUB_FIELDS,
  payload: fields,
})

// 更新当前选中的流程节点
export const updateTargetIndex = index => ({
  type: Constant.UPDATE_TARGET_INDEX,
  payload: index,
})

// 更新当前选中的流程节点
export const updateTargetNode = node => ({
  type: Constant.UPDATE_TARGET_NODE,
  payload: node,
})

// 更新当前选中的流程节点
export const updateTargetForm = form => ({
  type: Constant.UPDATE_TARGET_NODE_FORM,
  payload: form,
})

// 更新当前选中的流程节点
export const updateTargetLines = lines => ({
  type: Constant.UPDATE_TARGET_LINES,
  payload: lines,
})

// 更新当前选中的流程节点
export const updateOperateType = type => ({
  type: Constant.UPDATE_NODE_OPERATE_TYPE,
  payload: type,
})

// 更新当前选中的流程节点
export const updateOperateFields = fields => ({
  type: Constant.UPDATE_NODE_OPERATE_FIELDS,
  payload: fields,
})

export const updateFlowDefine = define => ({
  type: Constant.UPDATE_FLOW_DEFINE,
  payload: define,
})

export const updateFlowReadonly = define => ({
  type: Constant.UPDATE_FLOW_READONLY,
  payload: define,
})

export const updateFlowCanvasNodes = flowNodes => ({
  type: Constant.UPDATE_FLOW_CANVAS_NODES,
  payload: flowNodes,
})

export const updateFlowCanvasVectors = flowVectors => ({
  type: Constant.UPDATE_FLOW_CANVAS_VECTORS,
  payload: flowVectors,
})

export const updateFlowCanvasCenter = center => ({
  type: Constant.UPDATE_FLOW_CANVAS_CENTER,
  payload: center,
})

export const updateFlowKeyBase = keyBase => ({
  type: Constant.UPDATE_FLOW_KEY_BASE,
  payload: keyBase,
})
