import dayjs from 'dayjs'
import qs from 'query-string'

// @ts-ignore
export { default as prettysize } from 'prettysize'

export function empty(str: any) {
  return str === '' || str === undefined || str === null
}

export function isEmpty(str: any) {
  return empty(str)
}

export function ifEmpty(val: any, defaults: any) {
  return val === null || val === undefined ? defaults : val
}

export const DATE_FORMAT = {
  date: 'YYYY-MM-DD',
  datetime: 'YYYY-MM-DD HH:mm',
  datetime_h: 'YYYY-MM-DD HH:00',
  full: 'YYYY-MM-DD HH:mm:ss',
}

export function dateFormat(stamp: dayjs.ConfigType, format = DATE_FORMAT.datetime) {
  if (empty(stamp)) {
    return ''
  }
  return dayjs(stamp).format(format)
}

// 不兼容低版本浏览器（特指辣鸡企业微信）
// export function leftPad(str, len, ch) {
//   return String.prototype.padStart.call(str, len, ch)
// }

export function leftPad(str: string | number = '', len = 2, ch = '0') {
  const val = String(str)
  if (val.length < len) {
    return new Array(len - val.length).fill(ch).join('') + str
  }
  return val
}

// 链式异步调用
export function promiseChain(arr = [], promiseCallback: any) {
  const copy = arr.slice()
  let count = -1

  async function nextTick(resolve: any, reject: any) {
    count += 1
    const next = copy[count]
    if (!next) {
      resolve()
      return
    }

    try {
      await promiseCallback(next)
      nextTick(resolve, reject)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
      reject()
    }
  }

  return new Promise(function chain(resolve, reject) {
    nextTick(resolve, reject)
  })
}

export const ENV_TYPE = {
  DEV: 'DEV',
  TEST: 'TEST',
  PROD: 'PROD',
}

export const getEnvByLocation = () => {
  const { host } = window.location
  if (/^oa\./.test(host)) {
    return ENV_TYPE.PROD
  }
  if (/^oa-test\./.test(host)) {
    return ENV_TYPE.TEST
  }
  return ENV_TYPE.DEV
}

export const NODE_ENV_TYPE = {
  DEVELOPMENT: 'development',
  TEST: 'test',
  // TESTAPP: 'testapp',
  PRODUCTION: 'production',
  PREVIEW: 'preview',
}

export const getEnvByEnv = () => {
  const { REACT_APP_NODE_ENV } = process.env
  return REACT_APP_NODE_ENV
}

export const getLocalStorage = (key: any, complex: any) => {
  const value = window.localStorage.getItem(key)
  let params = {}
  if (complex) {
    params = JSON.parse(value || '{}')
  }
  return complex ? params : value
}

export const setLocalStorage = (key: any, value: any, complex?: any, name?: any) => {
  if (complex) {
    const params = { ...getLocalStorage(key, true), [name]: value }
    window.localStorage.setItem(key, JSON.stringify(params))
  } else {
    window.localStorage.setItem(key, value)
  }
}

export const clearLocalStorage = (key: any, complex: any, name: any) => {
  if (complex) {
    const params = { ...getLocalStorage(key, true) }
    // @ts-ignore
    delete params[name]
    window.localStorage.setItem(key, JSON.stringify(params))
  } else {
    window.localStorage.removeItem(key)
  }
}

export function getLocationSearch(extraProps = {}): Record<string, any> {
  const params = qs.parse(window.location.search)

  // url参数覆盖传入的参数
  return {
    ...extraProps,
    ...params,
  }
}

export function guid() {
  const r = (size = 4) =>
    Math.random()
      .toString(16)
      .substr(2, size)
  return `${r(8)}-${r()}-${r()}-${r()}-${r(12)}`
}

export function stringifyQuery(query: Record<string, any>) {
  return qs.stringify(query, {
    skipEmptyString: true,
    skipNull: true,
  })
}

export function getImageSize(
  url: string | undefined,
  callback?: (data: { width: number; height: number }) => void
) {
  if (!url) {
    callback?.({ width: 0, height: 0 })
  } else {
    const image = new Image()
    image.onload = () => {
      callback?.({ width: image.width, height: image.height })
    }
    image.src = url
  }
}
