import React from 'react'
import classNames from 'classnames'

interface Props {
  icon: string
  className?: string
  fill?: string
}

export default React.memo(({ icon, className, fill = 'inherit' }: Props) => (
  <svg className={classNames('svg-icon', `svg-icon-${icon}`, className)} fill={fill}>
    <use xlinkHref={`#svg-icon-${icon}`} />
  </svg>
))
