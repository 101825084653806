import React from 'react'
import { matchRoutes } from 'react-router-config'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import classnames from 'classnames'

import Header from './Header'
import NavBar from './NavBar'
import Forbidden from '../components/ErrorPage/Forbidden'
import {
  collect,
  getCurrentFavMenu,
  getCurrentUserMenu,
  getCurrentUserRight,
} from '../../api/common'
import NewContext from './NewContext'
import Loading from '../components/Loading'

import { useGlobalContext } from '../context/GlobalContext'

import styles from './Main.module.less'
import { USER_TYPE } from '../../constants'

const isDev = process.env.NODE_ENV === 'development'

const formatAuth = modules => {
  const auth = {}
  modules.forEach(module => {
    module.child.forEach(app => {
      app.child.forEach(item => {
        auth[item.url] = { ...item }
      })
    })
  })
  return auth
}

function Main({ routes, children }) {
  const {
    setFavList,
    authData,
    setRights,
    setRightsFetched,
    setMenuList,
    setAuthData,
    authFetched,
    setAuthFetched,
    currentUser,
  } = useGlobalContext()
  const location = useLocation()
  const history = useHistory()
  const match = useRouteMatch()
  const [fixHeader, setFixHeader] = React.useState(false)
  const [noBread, setNoBread] = React.useState(false)
  const [hideSidebar, setHideSidebar] = React.useState(false)
  const [forbidden, setForbidden] = React.useState(false)
  const [refreshFav, setRefreshFav] = React.useState(false)
  const [refreshMenu, setRefreshMenu] = React.useState(false)
  const [refreshRights, setRefreshRights] = React.useState(false)
  const isLoginPage = location.pathname === '/login'
  const authDataRef = React.useRef()
  const pathnameRef = React.useRef()

  const checkMenuAuth = React.useCallback(() => {
    const branch = matchRoutes(routes, location.pathname)
    const [matched = {}] = branch
    const { route } = matched
    if (route) {
      const { menuBelong } = route
      if (currentUser && currentUser.type === USER_TYPE.SUPER_ADMIN) {
        setForbidden(false)
        return
      }
      if (menuBelong && authData && !authData[menuBelong]) {
        setForbidden(true)
      } else {
        setForbidden(false)
      }
    }
  }, [authData, location.pathname, routes, currentUser])

  React.useEffect(() => {
    if (refreshFav) {
      const getFav = async () => {
        const resp = await getCurrentFavMenu()
        const { data } = resp.data
        setRefreshFav(false)
        setFavList(data)
      }
      getFav()
    }
  }, [refreshFav, setFavList])

  React.useEffect(() => {
    if (refreshMenu) {
      const getMenu = async () => {
        const resp = await getCurrentUserMenu()
        const { data } = resp.data
        setRefreshMenu(false)
        setAuthData(formatAuth(data))
        setAuthFetched(true)
        setMenuList(data)
      }
      getMenu()
    }
  }, [refreshMenu, setAuthData, setAuthFetched, setMenuList])

  React.useEffect(() => {
    if (refreshRights) {
      const getRights = async () => {
        const resp = await getCurrentUserRight()
        const { data } = resp.data
        setRefreshRights(false)
        setRights(data)
        setRightsFetched(true)
      }
      getRights()
    }
  }, [refreshRights, setRights, setRightsFetched])

  React.useEffect(() => {
    // prev
    // this.props.auth !== prevProps.auth || this.props.location.pathname !== prevProps.location.pathname
    if (authData !== authDataRef.current) {
      checkMenuAuth()
      authDataRef.current = authData
    }
  }, [authData, checkMenuAuth])

  React.useEffect(() => {
    // prev
    // this.props.auth !== prevProps.auth || this.props.location.pathname !== prevProps.location.pathname
    if (location.pathname !== pathnameRef.current) {
      checkMenuAuth()
      pathnameRef.current = location.pathname
    }
  }, [checkMenuAuth, location.pathname])

  React.useEffect(() => {
    if (
      currentUser &&
      currentUser.userId &&
      (!currentUser.pwdFlag || (currentUser.type === USER_TYPE.STAFF && !currentUser.postId)) &&
      location.pathname !== '/setting/user'
    ) {
      // 当前登录用户，没有修改密码/没有设置默认岗位
      history.replace('/setting/user')
    }
  }, [history, location.pathname, currentUser])

  React.useEffect(() => {
    if (!isLoginPage) {
      setRefreshFav(true)
      setRefreshMenu(true)
      setRefreshRights(true)
    }
  }, [isLoginPage])

  React.useEffect(() => {
    if (location.pathname && !isLoginPage) {
      collect(match.url)
      const checkLayout = () => {
        const branch = matchRoutes(routes, location.pathname)
        const [matched = {}] = branch
        const { route } = matched

        if (route) {
          setFixHeader(route.fixHeader)
          setNoBread(route.noBread)
          setHideSidebar(route.hideSidebar)
        }
      }
      checkLayout()
    }
  }, [isLoginPage, location.pathname, match.url, routes])

  if (isLoginPage) {
    return children
  }

  // 开发模式下需要等待当前登录用户信息加载完成再继续渲染
  if (isDev && (!currentUser || !currentUser.userId)) {
    return (
      <div className={styles['loading-box']}>
        <Loading.Ellipsis />
      </div>
    )
  }

  if (!authFetched) {
    return (
      <div className={styles['loading-box']}>
        <Loading.Ellipsis />
      </div>
    )
  }

  return (
    <NewContext>
      <div className={classnames('body', { 'header-fixed': fixHeader, 'no-breadcrumb': noBread })}>
        <Header />
        <div className="wrap">
          {!hideSidebar && <NavBar onUpdateFav={() => setRefreshFav(true)} />}
          <div
            className="content"
            style={{
              // maxWidth: hideSidebar ? 'none' : `calc(100% - 240px)`,
              minWidth: 960,
            }}
          >
            {forbidden ? <Forbidden /> : children}
          </div>
        </div>
      </div>
    </NewContext>
  )
}

export default Main
