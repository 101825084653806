// lib
import React from 'react'

// styles
import './style.less'

const Melody = () => (
  <div className="melody">
    <span />
    <span />
    <span />
    <span />
    <span />
    <span />
    <span />
  </div>
)

const Ellipsis = () => (
  <div className="ellipsis">
    <span />
  </div>
)

export default {
  Melody,
  Ellipsis,
}
