import AsyncComponent from './AsyncComponent'

const FeedbackList = AsyncComponent(() =>
  import(/* webpackChunkName: 'feedback-list' */ '../@feedback/List')
)
const FeedbackEdit = AsyncComponent(() =>
  import(/* webpackChunkName: 'feedback-edit' */ '../@feedback/Edit')
)
const FeedbackDetail = AsyncComponent(() =>
  import(/* webpackChunkName: 'feedback-detail' */ '../@feedback/Detail')
)

export default [
  {
    path: '/feedback/list',
    component: FeedbackList,
    title: '意见箱',
    exact: true,
    menuBelong: '/feedback/list',
  },
  {
    path: '/feedback/edit/:id',
    component: FeedbackEdit,
    title: '提交意见',
    exact: true,
    menuBelong: '/feedback/list',
  },
  {
    path: '/feedback/detail/:id',
    component: FeedbackDetail,
    title: '意见箱详情',
    exact: true,
    menuBelong: '/feedback/list',
  },
]
