import React, { Dispatch, useContext } from 'react'

export const GlobalContext = React.createContext<{
  currentUser?: UserVO
  userRights: string[]
  userMenu: string[]
  userMenuAuth?: Record<any, any>
  userWords: string[]
  dispatch: Dispatch<{ type: string; payload: any }>
  rightsFetched: boolean
}>({
  userRights: [],
  userMenu: [],
  userWords: [],
  dispatch() {},
  rightsFetched: false,
})

const GlobalProvider = GlobalContext.Provider
export default GlobalProvider

export function useGlobal() {
  const context = useContext(GlobalContext)
  if (typeof context === 'undefined') {
    throw new Error('useGlobal must be used within a GlobalProvider')
  }
  return context
}
