import qs from 'query-string'
import { clearLocalStorage } from '../utils'

const { NODE_ENV } = process.env
const isDev = NODE_ENV === 'development'

export function catchUnauthorized(response) {
  const { pathname, search } = window.location
  clearLocalStorage('token')
  if (/\/login$/.test(pathname)) {
    // 登录页抓取数据不做401跳转
    return Promise.reject(response)
  }

  const originalUrl = pathname + search
  const { isMobile, isApp } = window.__INITIAL_MODEL_DATA || {}
  // app内打开没有登录调取app内授权
  if (isApp) {
    const querystring = qs.stringify({
      originalUrl: pathname,
      ...qs.parse(search),
    })
    window.location.replace(`/auth0/app/reauth?${querystring}`)
  } else if (isMobile) {
    window.location.replace(`/m/login?originalUrl=${encodeURIComponent(originalUrl)}`)
  } else {
    window.location.replace(`/login?originalUrl=${encodeURIComponent(originalUrl)}`)
  }

  return Promise.reject(response)
}

export const XMLConfig = {
  headers: {
    'content-type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
  withCredentials: isDev,
}
