import { Constant } from '../actions/positionAction'

const initialState = {
  positions: [],
  selected: [],
  needLevel: false,
  levels: {},
  multiple: true,
}

export function positionReducer(state = { ...initialState }, action) {
  const { type, payload } = action
  switch (type) {
    case Constant.UPDATE_POSITIONS:
      return { ...state, positions: payload }
    case Constant.UPDATE_POSITION_SELECTED:
      return { ...state, selected: payload }
    case Constant.UPDATE_NEED_LEVEL:
      return { ...state, needLevel: payload }
    case Constant.UPDATE_POSITION_LEVELS:
      return { ...state, levels: payload }
    case Constant.UPDATE_POSITION_MULTIPLE:
      return { ...state, multiple: payload }
    default:
      break
  }
  return state
}
