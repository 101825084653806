import React from 'react'
import ReactDOM from 'react-dom'
import { message } from 'antd'
// eslint-disable-next-line
import { AppContainer, hot } from 'react-hot-loader'

import App from './@pc'

import global from './@pc/utils/global'

const root = document.getElementById('app')

window.utils = global
window.message = msg => {
  message.warn(msg, 3)
}

function render(Component) {
  if (process.env.NODE_ENV === 'development') {
    const RHLComponent = hot(module)(Component)
    ReactDOM.render(
      <AppContainer>
        <RHLComponent />
      </AppContainer>,
      root
    )
  } else {
    ReactDOM.render(<Component />, root)
  }
}

render(App)

// webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./@pc', () => {
    // if you are using harmony modules ({modules:false})
    // render(App)
    // in all other cases - re-require App manually
    // eslint-disable-next-line
    render(require('./@pc').default)
  })
}
