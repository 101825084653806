import React, { ReactNode } from 'react'

import GlobalProvider from '../../context'
import useCommonContext from '../../context/common'
import { useGlobalContext } from '../context/GlobalContext'

function NewContext({ children }: { children: ReactNode }) {
  const { currentUser, rights: userRights, rightsFetched } = useGlobalContext()

  const [commonState, commonDispatch] = useCommonContext({
    currentUser,
    userRights,
  })

  return (
    <GlobalProvider value={{ ...commonState, rightsFetched, dispatch: commonDispatch }}>
      {children}
    </GlobalProvider>
  )
}

export default NewContext
