import axios from 'axios'
import request from './request'

const isDevelopment = process.env.NODE_ENV === 'development'

interface OSSPolicy {
  OSSAccessKeyId: string
  host: string
  policy: string
  signature: string
  expire: number
  dir: string
}

export function getCurrentUser() {
  return request.get('/user/curUser')
}

export function getNodejsUtoken() {
  return request.get('/nodejs/utoken')
}

export function getCurrentUserRight() {
  return request.get('/user/rights/get')
}

export function getCurrentUserMenu() {
  return request.get('/menu/user?hasSelf=1')
}

export function getCurrentFavMenu() {
  return request.get('/collect/list')
}

export function deleteFavMenu(id: any) {
  return request.post(`/collect/delete/${id}`)
}

export function getUserMemo() {
  return request.get('/userMemo/get')
}

export function saveUserMemo(data: any) {
  return request.post('/userMemo/save', data)
}

export function getUploadSign(dir = '/', group: 'private' | 'public') {
  return request.get<R<OSSPolicy>>(`/upload/${group}/sign`, { baseURL: '/', params: { dir } })
}

export function upload(formData: any, type: any, onProgress: any) {
  return request.post(`/upload/${type}`, formData, {
    baseURL: '/',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress(evt) {
      const uploadPercentage = parseInt(String(Math.round((evt.loaded * 100) / evt.total)), 10)
      if (typeof onProgress === 'function') {
        onProgress(uploadPercentage)
      }
    },
  })
}

export function uploadHost(
  host: string,
  formData: FormData,
  onProgress?: (p: number) => void,
  onFailure?: () => void
) {
  return axios
    .post(host, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      },
      onUploadProgress(evt) {
        const uploadPercentage = parseInt(String(Math.round((evt.loaded * 100) / evt.total)), 10)
        if (typeof onProgress === 'function') {
          onProgress?.(uploadPercentage)
        }
      },
    })
    .catch(e => {
      if (typeof onFailure === 'function') {
        onFailure()
      }
      throw e
    })
}

export function collect(url: any) {
  if (!isDevelopment) {
    request.post('/statistics/visit', {
      url,
    })
  }
}

export function logout() {
  return request.post('/logout')
}

export function wxSignMap(url: any) {
  return request.post('/weixin/signMap', {
    url,
  })
}

export function login({ username, password }: any) {
  return request.post('/user/login', { username, password })
}

/**
 *
 * @param {number} type 1员工 2职员 3演员 99超级管理员
 * @param {string} searchStr
 * @param {number} pageSize
 * @param {number} pageNo
 * @param {number} used 0禁用 1可用
 */
export function getUserList({
  type = 1,
  searchStr,
  pageSize = 10,
  pageNo = 1,
  used = 1,
}: PageQueryParams = {}) {
  const params: Record<string, any> = { pageSize, pageNo }
  if (used || used === 0) {
    params['search_EQ_used'] = used
  }
  if (type) {
    params['search_EQ_type'] = type
  }
  if (searchStr) {
    params['searchStr'] = searchStr
  }
  return request.get<R<PageData<User>>>('/user/list', { params })
}

export function getUserPage({
  sortInfo = 'DESC_createTime',
  pageNo = 1,
  pageSize = 10,
  name,
  userCode,
  type,
}: PageQueryParams = {}) {
  const params: Record<string, any> = { pageSize, pageNo, sortInfo }
  if (name) {
    params['search_LIKE_name'] = name
  }
  if (userCode) {
    params['search_LIKE_userCode'] = userCode
  }
  if (type) {
    params['search_EQ_type'] = type
  }
  return request.get<R<PageData<User>>>('/user/page', { params })
}
