import AsyncComponent from './AsyncComponent'

const Temperature = AsyncComponent(() =>
  import(/* webpackChunkName: 'customization-temperature' */ '../@customization/Temperature')
)
const Checkin = AsyncComponent(() =>
  import(/* webpackChunkName: 'customization-checkin' */ '../@customization/Checkin')
)
const Framework = AsyncComponent(() =>
  import(/* webpackChunkName: 'customization-framework' */ '../@customization/Framework')
)

export default [
  {
    path: '/customization/temperature',
    component: Temperature,
    title: '体温监测',
    exact: true,
    menuBelong: '/customization/temperature',
  },
  {
    path: '/customization/checkin',
    component: Checkin,
    title: '考勤记录',
    exact: true,
    menuBelong: '/customization/checkin',
  },
  {
    path: '/customization/framework',
    component: Framework,
    title: '灵河文化规章制度',
    exact: true,
    hideSidebar: true,
  },
]
