import AsyncComponent from './AsyncComponent'

const MyTask = AsyncComponent(() => import(/* webpackChunkName: 'uc-task' */ '../@uc/MyTask'))
const MyApply = AsyncComponent(() => import(/* webpackChunkName: 'uc-apply' */ '../@uc/MyApply'))
const Message = AsyncComponent(() => import(/* webpackChunkName: 'uc-message' */ '../@uc/Message'))
const TaskTemplateList = AsyncComponent(() =>
  import(/* webpackChunkName: 'uc-task-template-list' */ '../@uc/TaskTemplateList')
)
const TaskTemplate = AsyncComponent(() =>
  import(/* webpackChunkName: 'uc-task-template' */ '../@uc/TaskTemplate')
)
const TaskList = AsyncComponent(() =>
  import(/* webpackChunkName: 'uc-task-list' */ '../@uc/TaskList')
)
const TaskAssign = AsyncComponent(() =>
  import(/* webpackChunkName: 'uc-task-assign' */ '../@uc/TaskAssign')
)
const MyTodoList = AsyncComponent(() =>
  import(/* webpackChunkName: 'uc-todo-list' */ '../@uc/MyTodoList')
)
const TaskWork = AsyncComponent(() =>
  import(/* webpackChunkName: 'uc-task-work' */ '../@uc/TaskWork')
)

export default [
  {
    path: '/uc/task',
    component: MyTask,
    title: '我的审批',
    exact: true,
  },
  {
    path: '/uc/apply',
    component: MyApply,
    title: '我的申请',
    exact: true,
  },
  {
    path: '/uc/message',
    component: Message,
    title: '消息中心',
    exact: true,
    hideSidebar: true,
    fixHeader: true,
  },
  {
    path: '/task/templates',
    component: TaskTemplateList,
    title: '任务模板',
    exact: true,
  },
  {
    path: '/task/template/:id',
    component: TaskTemplate,
    title: '任务模板编辑',
    exact: true,
  },
  {
    path: '/task/list',
    component: TaskList,
    title: '任务列表',
    exact: true,
  },
  {
    path: '/task/assign/:id',
    component: TaskAssign,
    title: '任务分配',
    exact: true,
  },
  {
    path: '/task/todolist',
    component: MyTodoList,
    title: '我的任务',
    exact: true,
  },
  {
    path: '/task/working/:id',
    component: TaskWork,
    title: '任务详情',
    exact: true,
  },
]
