import React, { ComponentType, lazy, Suspense } from 'react'

function AsyncComponent<T extends ComponentType<any>>(loader: () => Promise<{ default: T }>) {
  const TargetComponent = lazy(loader)
  return (props: React.ComponentPropsWithRef<T>) => (
    <Suspense fallback={null}>
      <TargetComponent {...props} />
    </Suspense>
  )
}

export default AsyncComponent
