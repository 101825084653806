import React from 'react'
import ReactDOM from 'react-dom'
import { Modal, message, Input } from 'antd'

export default {
  alert(msg, delay = 2500) {
    return message.info(msg, delay / 1000)
  },

  confirm(title, okText = '确定', cancelText = '取消') {
    return new Promise((resolve, reject) => {
      Modal.confirm({
        title,
        cancelText,
        okText,
        icon: null,
        onOk() {
          resolve()
        },
        onCancel() {
          reject()
        },
      })
    })
  },

  prompt(title, defaultValue = '', okText = '确定', cancelText = '取消', useText = false) {
    return new Promise((resolve, reject) => {
      const controlId = `promp-text-${Math.random()
        .toString(16)
        .substr(2, 8)}`
      const root = document.createElement('div')
      document.body.appendChild(root)

      function onCancel() {
        ReactDOM.unmountComponentAtNode(root)
        document.body.removeChild(root)
        reject()
      }

      function onSure() {
        const control = document.getElementById(controlId)
        resolve(control.value)
        ReactDOM.unmountComponentAtNode(root)
        document.body.removeChild(root)
      }

      function PromptModal() {
        return (
          <Modal
            title={title}
            visible
            okText={okText}
            cancelText={cancelText}
            onCancel={onCancel}
            onOk={onSure}
          >
            {useText ? (
              <Input id={controlId} defaultValue={defaultValue} placeholder={title} />
            ) : (
              <Input.TextArea
                id={controlId}
                defaultValue={defaultValue}
                placeholder={title}
                rows={4}
              />
            )}
          </Modal>
        )
      }

      ReactDOM.render(<PromptModal />, root)
    })
  },
}
