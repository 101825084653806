import { RouteConfig } from 'react-router-config'
import AsyncComponent from './AsyncComponent'

const routes: RouteConfig[] = [
  {
    path: '/kpi',
    exact: true,
    component: AsyncComponent(() => import(/* webpackChunkName: 'kpi' */ '../@kpi')),
    hideSidebar: true,
    noBread: true,
  },
  {
    path: '/kpi/task',
    exact: true,
    component: AsyncComponent(() => import(/* webpackChunkName: 'kpi-task' */ '../@kpi/task')),
    hideSidebar: true,
    noBread: true,
  },
  {
    path: '/kpi/task/todo',
    exact: true,
    component: AsyncComponent(() =>
      import(/* webpackChunkName: 'kpi-task-todo' */ '../@kpi/task/todo')
    ),
    hideSidebar: true,
    noBread: true,
  },
  {
    path: '/kpi/task/my',
    exact: true,
    component: AsyncComponent(() =>
      import(/* webpackChunkName: 'kpi-task-my' */ '../@kpi/task/my')
    ),
    hideSidebar: true,
    noBread: true,
  },
  {
    path: '/kpi/task/list',
    exact: true,
    component: AsyncComponent(() =>
      import(/* webpackChunkName: 'kpi-task-list' */ '../@kpi/task/list')
    ),
    hideSidebar: true,
    noBread: true,
  },
  {
    path: '/kpi/order/:orderId',
    exact: true,
    component: AsyncComponent(() => import(/* webpackChunkName: 'kpi-order' */ '../@kpi/order')),
    hideSidebar: true,
    noBread: true,
  },
  {
    path: '/kpi/setting',
    exact: true,
    component: AsyncComponent(() =>
      import(/* webpackChunkName: 'kpi-setting' */ '../@kpi/setting')
    ),
    hideSidebar: true,
    noBread: true,
  },
  {
    path: '/kpi/setting/plan',
    exact: true,
    component: AsyncComponent(() =>
      import(/* webpackChunkName: 'kpi-setting-plan' */ '../@kpi/setting/plan')
    ),
    hideSidebar: true,
    noBread: true,
  },
  {
    path: '/kpi/setting/users',
    exact: true,
    component: AsyncComponent(() =>
      import(/* webpackChunkName: 'kpi-setting-users' */ '../@kpi/setting/users')
    ),
    hideSidebar: true,
    noBread: true,
  },
]

export default routes
