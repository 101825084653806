import AsyncComponent from './AsyncComponent'

const AppModules = AsyncComponent(() =>
  import(/* webpackChunkName: 'workflow-app-modules' */ '../@workflow/AppModules')
)
const BasicInfoList = AsyncComponent(() =>
  import(/* webpackChunkName: 'workflow-basic-info-list' */ '../@workflow/BasicInfoList')
)
const BasicFormTable = AsyncComponent(() =>
  import(/* webpackChunkName: 'workflow-basic-form-table' */ '../@workflow/BasicFormTable')
)
const FormBoard = AsyncComponent(() =>
  import(/* webpackChunkName: 'workflow-form-board' */ '../@workflow/FormBoard/index')
)
const WorkForm = AsyncComponent(() =>
  import(/* webpackChunkName: 'workflow-work-form' */ '../@workflow/WorkForm')
)
const FormLayout = AsyncComponent(() =>
  import(/* webpackChunkName: 'workflow-form-layout' */ '../@workflow/FormLayout')
)
const Preview = AsyncComponent(() =>
  import(/* webpackChunkName: 'workflow-preview' */ '../@workflow/Preview')
)
const SearchForm = AsyncComponent(() =>
  import(/* webpackChunkName: 'workflow-search-form' */ '../@workflow/SearchForm')
)
const ExportSetting = AsyncComponent(() =>
  import(/* webpackChunkName: 'workflow-export-setting' */ '../@workflow/ExportSetting')
)
const WorkFlow = AsyncComponent(() =>
  import(/* webpackChunkName: 'workflow-workflow' */ '../@workflow/WorkFlow')
)
const MsgConfig = AsyncComponent(() =>
  import(/* webpackChunkName: 'workflow-msg-config' */ '../@workflow/MsgConfig')
)
const RelevancyPack = AsyncComponent(() =>
  import(/* webpackChunkName: 'workflow-relevancy-pack' */ '../@workflow/RelevancyPack')
)
const RelevancyList = AsyncComponent(() =>
  import(/* webpackChunkName: 'workflow-relevancy-list' */ '../@workflow/RelevancyList')
)
const FormRelevancy = AsyncComponent(() =>
  import(/* webpackChunkName: 'workflow-form-relevancy' */ '../@workflow/FormRelevancy')
)
const WriteAuth = AsyncComponent(() =>
  import(/* webpackChunkName: 'workflow-write-auth' */ '../@workflow/WriteAuth/index')
)
const Unique = AsyncComponent(() =>
  import(/* webpackChunkName: 'workflow-unique' */ '../@workflow/Unique')
)
const Valid = AsyncComponent(() =>
  import(/* webpackChunkName: 'workflow-valid' */ '../@workflow/Valid')
)
const DataAuth = AsyncComponent(() =>
  import(/* webpackChunkName: 'workflow-data-auth' */ '../@workflow/DataAuth')
)
const DataMessage = AsyncComponent(() =>
  import(/* webpackChunkName: 'workflow-data-message' */ '../@workflow/DataMessage')
)

export default [
  {
    path: '/workflow/apps',
    component: AppModules,
    title: '业务模块',
    exact: true,
    menuBelong: '/workflow/apps',
  },
  {
    path: '/workflow/basic/list',
    component: BasicInfoList,
    title: '基础信息主表',
    exact: true,
    menuBelong: '/workflow/apps',
  },
  {
    path: '/workflow/basic/form/table/:id',
    component: BasicFormTable,
    title: '基础信息表单字段维护',
    exact: true,
    menuBelong: '/workflow/apps',
  },
  {
    path: '/workflow/form/board/:id',
    component: FormBoard,
    title: '表单详情一览',
    exact: true,
    menuBelong: '/workflow/apps',
    hideSidebar: true,
  },
  {
    path: '/workflow/form/table/:id',
    component: WorkForm,
    title: '业务表单详情',
    exact: true,
    menuBelong: '/workflow/apps',
  },
  {
    path: '/workflow/form/:formId/layout/:layoutId',
    component: FormLayout,
    title: '表单布局',
    exact: true,
    menuBelong: '/workflow/apps',
  },
  {
    path: '/workflow/form/:formId/layout/:layoutId/:detailId',
    component: FormLayout,
    title: '明细表布局',
    exact: true,
    menuBelong: '/workflow/apps',
  },
  {
    path: '/workflow/form/preview/:layoutId',
    component: Preview,
    title: '表单布局预览',
    exact: true,
    menuBelong: '/workflow/apps',
  },
  {
    path: '/workflow/form/search/:tableId',
    component: SearchForm,
    title: '维护查询表单',
    exact: true,
    menuBelong: '/workflow/apps',
  },
  {
    path: '/workflow/form/export/setting/:tableId',
    component: ExportSetting,
    title: '维护导出字段',
    exact: true,
    menuBelong: '/workflow/apps',
  },
  {
    path: '/workflow/form/flow/:tableId/:defId',
    component: WorkFlow,
    title: '流程设置',
    exact: true,
    menuBelong: '/workflow/apps',
    hideSidebar: true,
  },
  {
    path: '/workflow/message/config/:tableId',
    component: MsgConfig,
    title: '流程消息配置',
    exact: true,
    menuBelong: '/workflow/apps',
  },
  {
    path: '/workflow/relevancy',
    component: RelevancyPack,
    title: '关联依据设置',
    exact: true,
    menuBelong: '/workflow/relevancy',
  },
  {
    path: '/workflow/relevancy/:packId/list',
    component: RelevancyList,
    title: '关联依据表列表',
    exact: true,
    menuBelong: '/workflow/relevancy',
  },
  {
    path: '/workflow/form/relevancy/:mdtableId',
    component: FormRelevancy,
    title: '表单关联设置',
    exact: true,
    menuBelong: '/workflow/apps',
  },
  {
    path: '/workflow/writeAuth/:mdtableId',
    component: WriteAuth,
    title: '编辑删除权限设置',
    exact: true,
    menuBelong: '/workflow/apps',
  },
  {
    path: '/workflow/unique/:mdtableId',
    component: Unique,
    title: '数据唯一性设置',
    exact: true,
    menuBelong: '/workflow/apps',
  },
  {
    path: '/workflow/valid/:mdtableId',
    component: Valid,
    title: '校验规则',
    exact: true,
    menuBelong: '/workflow/apps',
  },
  {
    path: '/workflow/dataAuth/:mdtableId',
    component: DataAuth,
    title: '设置权限',
    exact: true,
    menuBelong: '/workflow/apps',
  },
  {
    path: '/workflow/data/message/:mdtableId',
    component: DataMessage,
    title: '通知规则',
    exact: true,
    menuBelong: '/workflow/apps',
  },
]
