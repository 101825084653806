import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import Icon from '../components/base/Icon'
import axios from '../utils/axios'
import { useGlobalContext } from '../context/GlobalContext'

function Menu() {
  const location = useLocation()
  const { pathname } = location
  return (
    <ul className="menu">
      <li>
        <Link to="/" className={pathname === '/' ? ' active' : ''}>
          <Icon icon="home-dashboard" /> Dashboard
        </Link>
      </li>
      <li>
        <Link to="/uc/apply" className={pathname === '/uc/apply' ? ' active' : ''}>
          <Icon icon="home-apply" /> 我的申请
        </Link>
      </li>
      <li>
        <Link to="/uc/task" className={pathname === '/uc/task' ? ' active' : ''}>
          <Icon icon="home-task" /> 我的审批
        </Link>
      </li>
      <li>
        <Link to="/task/todolist" className={pathname === '/task/todolist' ? ' active' : ''}>
          <Icon icon="home-todolist" /> 我的任务
        </Link>
      </li>
    </ul>
  )
}

function NavBar({ onUpdateFav }) {
  const { favList, menuList } = useGlobalContext()
  const [favs, setFavs] = React.useState({})
  const [active, setActive] = React.useState(-1)

  function mapFav(list) {
    const newFavs = list.reduce((prevFavs, item) => {
      return {
        ...prevFavs,
        [item.id]: true,
      }
    }, {})
    setFavs(newFavs)
  }

  React.useEffect(() => {
    if (favList.length !== Object.keys(favs).length) {
      mapFav(favList)
    }
  }, [favList, favs])

  async function toggleFav(item) {
    if (favs[item.id]) {
      await axios.post(`/collect/delete/${item.id}`)
      setFavs({
        ...favs,
        [item.id]: false,
      })
      onUpdateFav()
    } else {
      await axios.post(`/collect/add`, {
        itemId: item.id,
      })
      setFavs({
        ...favs,
        [item.id]: true,
      })
      onUpdateFav()
    }
  }

  function renderMenuItem(item) {
    const linkArr = []
    if (item.type === 3) {
      linkArr.push(
        <a key={1} href={item.url} target="_blank" rel="noopener noreferrer">
          {item.menuName}
        </a>
      )
    } else {
      if (item.showApply) {
        const url = `/application/${item.type === 1 ? 'flow' : 'data'}/${item.url}/new`
        linkArr.push(
          <a key={2} href={url}>
            {item.applyName}
          </a>
        )
      }
      if (item.showList) {
        const url = `/application/${item.type === 1 ? 'flow' : 'data'}list/${item.url}`
        linkArr.push(
          <a key={3} href={url}>
            {item.listName}
          </a>
        )
      }
    }

    linkArr.push(
      <a key={4} onClick={() => toggleFav(item)}>
        {favs[item.id] ? '取消' : '添加'}收藏
      </a>
    )
    return linkArr
  }

  function renderAppMenu(module) {
    return (
      <ul className="menu-apps">
        {module.child.map(app => {
          return (
            <li key={app.id}>
              <div className="app-name">{app.name}</div>
              <ul className="menu-items">
                {app.child.map(item => {
                  return (
                    <li key={item.id}>
                      <Icon icon={item.icon || 'library-44'} />
                      <p>{item.menuName}</p>
                      <div className="menu-link">{renderMenuItem(item)}</div>
                    </li>
                  )
                })}
              </ul>
            </li>
          )
        })}
      </ul>
    )
  }

  function renderAuthMenu() {
    return (
      <div className="auth-menu">
        <h3>应用</h3>
        <ul className="menu-module">
          {menuList.map(module => {
            return (
              <li
                key={module.id}
                className={module.id === active ? 'hover' : ''}
                onMouseOver={() => setActive(module.id)}
                onMouseOut={() => setActive(-1)}
                onClick={() => {
                  if (active === module.id) {
                    setActive(-1)
                  } else {
                    setActive(module.id)
                  }
                }}
              >
                <div className="module-name">
                  <Icon icon={module.icon || 'library-44'} /> {module.name}
                  <Icon icon="right" />
                </div>
                {renderAppMenu(module)}
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  return (
    <div className="navbar">
      <div className="nav-menu">
        <Menu />
        {renderAuthMenu()}
      </div>
    </div>
  )
}

export default NavBar
