import React from 'react'

const isDev = process.env.NODE_ENV === 'development'

function useModuleMessage() {
  const [allUnReadCount, setAllUnReadCount] = React.useState(0)
  const [hasMessageModule] = React.useState(() => {
    try {
      return typeof window?.dpJsBridge?.moduleMessage === 'function'
    } catch {
      return false
    }
  })

  React.useEffect(() => {
    let clearMessage: () => void
    if (hasMessageModule && !isDev) {
      const { clear } = window.dpJsBridge.moduleMessage(
        {
          prefix: isDev ? '/deeppaasInterface' : '/deeppaas',
        },
        data => {
          setAllUnReadCount(data.unReadCount)
        }
      )
      clearMessage = clear
    }
    return () => {
      if (typeof clearMessage === 'function') {
        clearMessage()
      }
    }
  }, [hasMessageModule])

  return { allUnReadCount, hasMessageModule }
}

export default useModuleMessage
