import { RouteConfig } from 'react-router-config'
import AsyncComponent from './AsyncComponent'

const routes: RouteConfig[] = [
  {
    path: '/flows',
    exact: true,
    component: AsyncComponent(() => import('../@visio')),
  },
  {
    path: '/flows/:id',
    exact: true,
    component: AsyncComponent(() => import('../@visio/VisioFlow')),
    hideSidebar: true,
  },
]

export default routes
