export const DISPLAY_TYPES = {
  main_title: 'h1',
  half_title: 'h2',
  explain: 'html',
  field: 'field',
  detail_table: 'subTable',
  relative_package: 'relativePackage',
}

export const VIEW_STATUS = {
  HIDE: 0,
  READ: 1,
  EDIT: 2,
}

export const DATA_TYPE = {
  DICTIONARY: 1,
  TABLE: 2,
}

export const DATA_SOURCE_TYPE = {
  EMPTY: 0,
  DICTIONARY: 1,
  TABLE: 2,
  EXPRESSION: 3,
}

export const DATE_FORMAT = {
  date: 'YYYY-MM-DD',
  datetime: 'YYYY-MM-DD HH:mm',
  datetime_h: 'YYYY-MM-DD HH:00',
  full: 'YYYY-MM-DD HH:mm:ss',
}

export const ORG_TYPE = {
  USER: 3,
  POST: 2,
  DEPT: 1,
  ROOT: 0,
} as const

export const SCOPE = {
  ALL: 'ALL',
  MANY: 'MANY',
  ONE: 'ONE',
}

export const USER_RIGHT_CODE = {
  MEETING_ROOM_SETTING: 'MEETING_ROOM_SETTING',
  FEEDBACK_ADMINISTRATOR: 'FEEDBACK_ADMINISTRATOR',
  ASSET_ADMINISTRATOR: 'ASSET_ADMINISTRATOR',
}

export const WEBSOCKET_MESSAGE_TYPE = {
  GET_UNREAD_MESSAGE: 'GET_UNREAD_MESSAGE', // 获取未读消息
  UNREAD_MESSAGE_COUNT: 'UNREAD_MESSAGE_COUNT', // 未读消息数量
}

export const LOCALSTORAGE_KEY = {
  ACTIVITY_IMAGE_TIME: 'ACTIVITY_IMAGE_TIME',
}

export const USER_TYPE = {
  STAFF: 1,
  CLERK: 2,
  ACTOR: 3,
  SUPER_ADMIN: 99,
}

export const USER_TYPE_LABEL = {
  [USER_TYPE.STAFF]: '员工',
  [USER_TYPE.CLERK]: '职员',
  [USER_TYPE.ACTOR]: '演员',
  [USER_TYPE.SUPER_ADMIN]: '超级管理员',
}

export const USER_TYPE_LIST = [
  {
    value: USER_TYPE.STAFF,
    label: USER_TYPE_LABEL[USER_TYPE.STAFF],
  },
  {
    value: USER_TYPE.CLERK,
    label: USER_TYPE_LABEL[USER_TYPE.CLERK],
  },
  {
    value: USER_TYPE.ACTOR,
    label: USER_TYPE_LABEL[USER_TYPE.ACTOR],
  },
  {
    value: USER_TYPE.SUPER_ADMIN,
    label: USER_TYPE_LABEL[USER_TYPE.SUPER_ADMIN],
  },
]

export const OSS_COMMON_FOLDER = 'oa'
