import React from 'react'

import { XMLConfig } from '../../api/utils'

const InitialData = (window as any).__INITIAL_MODEL_DATA || {}
const isDev = process.env.NODE_ENV === 'development'

interface GlobalContextProps {
  unReadMessageCount: number
  setUnReadMessageCount: React.Dispatch<React.SetStateAction<number>>
  favList: UserCollectVO[]
  setFavList: React.Dispatch<React.SetStateAction<UserCollectVO[]>>
  rights: any[]
  setRights: React.Dispatch<React.SetStateAction<any[]>>
  rightsFetched: boolean
  setRightsFetched: React.Dispatch<React.SetStateAction<boolean>>
  menuList: any[]
  setMenuList: React.Dispatch<React.SetStateAction<any[]>>
  authData: any
  setAuthData: React.Dispatch<React.SetStateAction<any[]>>
  authFetched: boolean
  setAuthFetched: React.Dispatch<React.SetStateAction<boolean>>
  currentUser: UserVO
  setCurrentUser: React.Dispatch<React.SetStateAction<UserVO>>
}

const GlobalContext = React.createContext<GlobalContextProps>({
  unReadMessageCount: 0,
  setUnReadMessageCount: () => {},
  favList: [],
  setFavList: () => {},
  rights: [],
  setRights: () => {},
  rightsFetched: false,
  setRightsFetched: () => {},
  menuList: [],
  setMenuList: () => {},
  authData: {},
  setAuthData: () => {},
  authFetched: false,
  setAuthFetched: () => {},
  currentUser: {} as UserVO,
  setCurrentUser: () => {},
})

export function useGlobalContext() {
  return React.useContext(GlobalContext)
}

export default GlobalContext

function fetchInitialData() {
  return window
    .fetch('/api/user/curUser', {
      ...XMLConfig,
    })
    .then(res => res.json())
}

export function useCurrentUser(): [UserVO, React.Dispatch<React.SetStateAction<UserVO>>] {
  const [currentUser, setCurrentUser] = React.useState<UserVO>(InitialData.currentUser)

  React.useEffect(() => {
    async function getInitialData() {
      try {
        const userResp = await fetchInitialData()
        if (userResp.errorCode > 0) {
          throw new Error(JSON.stringify(userResp))
        }
        const { data } = userResp
        setCurrentUser(data)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    }

    if (isDev) {
      getInitialData()
    }
  }, [])

  return [currentUser, setCurrentUser]
}
