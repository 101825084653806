export const Constant = {
  UPDATE_POSITIONS: '@post/UPDATE_POSITIONS',
  UPDATE_POSITION_SELECTED: '@post/UPDATE_POSITION_SELECTED',
  UPDATE_POSITION_LEVELS: '@post/UPDATE_POSITION_LEVELS',
  UPDATE_NEED_LEVEL: '@post/UPDATE_NEED_LEVEL',
  UPDATE_POSITION_MULTIPLE: '@post/UPDATE_POSITION_MULTIPLE',
}

// 更新通用岗位数据
export const updatePositions = payload => ({
  type: Constant.UPDATE_POSITIONS,
  payload,
})

// 更新选中通用岗位
export const updatePositionSelected = payload => ({
  type: Constant.UPDATE_POSITION_SELECTED,
  payload,
})

// 更新通用岗位部门级别
export const updatePositionLevel = payload => ({
  type: Constant.UPDATE_POSITION_LEVELS,
  payload,
})

// 是否需要选择岗位的默认部门级别
export const updateNeedDeptLevel = payload => ({
  type: Constant.UPDATE_NEED_LEVEL,
  payload,
})

// 是否多选
export const updatePositionMultiple = payload => ({
  type: Constant.UPDATE_POSITION_MULTIPLE,
  payload,
})
