import AsyncComponent from './AsyncComponent'

const ReserveList = AsyncComponent(() =>
  import(/* webpackChunkName: 'metting-reserve-list' */ '../@meeting/ReserveList')
)
const RoomReserveList = AsyncComponent(() =>
  import(/* webpackChunkName: 'metting-room-reserve-list' */ '../@meeting/RoomReserveList')
)
const Reserve = AsyncComponent(() =>
  import(/* webpackChunkName: 'metting-reserve' */ '../@meeting/Reserve')
)
const ManageList = AsyncComponent(() =>
  import(/* webpackChunkName: 'metting-manage-list' */ '../@meeting/ManageList')
)
const Manage = AsyncComponent(() =>
  import(/* webpackChunkName: 'metting-manage' */ '../@meeting/Manage')
)
const MyRoomList = AsyncComponent(() =>
  import(/* webpackChunkName: 'metting-my-list' */ '../@meeting/MyRoomList')
)
const ReserveDetail = AsyncComponent(() =>
  import(/* webpackChunkName: 'metting-reserve-detail' */ '../@meeting/ReserveDetail')
)

export default [
  {
    path: '/meeting/room/my/list',
    component: MyRoomList,
    title: '我的会议室',
    exact: true,
    hideSidebar: true,
    menuBelong: '/meeting/room/list',
  },
  {
    path: '/meeting/room/detail/:reserveId',
    component: ReserveDetail,
    title: '会议室预定详情',
    exact: true,
    hideSidebar: true,
    menuBelong: '/meeting/room/list',
  },
  {
    path: '/meeting/room/manage/list',
    component: ManageList,
    title: '会议室管理',
    exact: true,
    hideSidebar: true,
    menuBelong: '/meeting/room/list',
  },
  {
    path: '/meeting/room/manage/:roomId',
    component: Manage,
    title: '会议室管理',
    exact: true,
    hideSidebar: true,
    menuBelong: '/meeting/room/list',
  },
  {
    path: '/meeting/room/list',
    component: ReserveList,
    title: '会议室预定',
    exact: true,
    hideSidebar: true,
    menuBelong: '/meeting/room/list',
  },
  {
    path: '/meeting/room/:roomId',
    component: RoomReserveList,
    title: '会议室详情',
    exact: true,
    hideSidebar: true,
    menuBelong: '/meeting/room/list',
  },
  {
    path: '/meeting/room/:roomId/:id',
    component: Reserve,
    title: '会议室预定',
    exact: true,
    hideSidebar: true,
    menuBelong: '/meeting/room/list',
  },
]
