import AsyncComponent from './AsyncComponent'

// 入库单
const InboundEdit = AsyncComponent(() => import('../@asset/Edit/Inbound'))
const InboundList = AsyncComponent(() => import('../@asset/List/InboundList'))
const InboundDetail = AsyncComponent(() => import('../@asset/Detail/InboundDetail'))
// 固定资产
const AssetList = AsyncComponent(() => import('../@asset/List/AssetList'))
const AssetDetail = AsyncComponent(() => import('../@asset/Detail/AssetDetail'))
// 资产类别
const TypeList = AsyncComponent(() => import('../@asset/List/TypeList'))
// 资产公司
const CompanyList = AsyncComponent(() => import('../@asset/List/CompanyList'))
// 资产流水号
const CodeList = AsyncComponent(() => import('../@asset/List/CodeList'))
// 资产盘点
const AssetCheckList = AsyncComponent(() => import('../@asset/List/AssetCheckList'))
const AssetCheckSet = AsyncComponent(() => import('../@asset/Edit/AssetCheckSet'))
const AssetCheckItemList = AsyncComponent(() => import('../@asset/List/AssetCheckItemList'))
const AssetQRCodeDownload = AsyncComponent(() => import('../@asset/List/QRCodeDownload'))

export default [
  {
    path: '/assets/inbound/new',
    component: InboundEdit,
    title: '新增入库单',
    exact: true,
    hideSidebar: true,
    menuBelong: '/assets/list',
  },
  {
    path: '/assets/inbound/list',
    component: InboundList,
    title: '入库单',
    exact: true,
    hideSidebar: true,
    menuBelong: '/assets/list',
  },
  {
    path: '/assets/inbound/detail/:id',
    component: InboundDetail,
    title: '入库单详情',
    exact: true,
    hideSidebar: true,
    menuBelong: '/assets/list',
  },
  {
    path: '/assets/list',
    component: AssetList,
    title: '固定资产',
    exact: true,
    hideSidebar: true,
    menuBelong: '/assets/list',
  },
  {
    path: '/assets/detail/:id',
    component: AssetDetail,
    title: '固定资产详情',
    exact: true,
    hideSidebar: true,
    menuBelong: '/assets/list',
  },
  {
    path: '/assets/type/list',
    component: TypeList,
    title: '资产类别',
    exact: true,
    hideSidebar: true,
    menuBelong: '/assets/list',
  },
  {
    path: '/assets/company/list',
    component: CompanyList,
    title: '资产公司',
    exact: true,
    hideSidebar: true,
    menuBelong: '/assets/list',
  },
  {
    path: '/assets/code/list',
    component: CodeList,
    title: '资产流水号',
    exact: true,
    hideSidebar: true,
    menuBelong: '/assets/list',
  },
  {
    path: '/assets/check/list',
    component: AssetCheckList,
    title: '资产盘点',
    exact: true,
    hideSidebar: true,
    menuBelong: '/assets/list',
  },
  {
    path: '/assets/check/set/:id',
    component: AssetCheckSet,
    title: '资产盘点设置',
    exact: true,
    hideSidebar: true,
    menuBelong: '/assets/list',
  },
  {
    path: '/assets/check/:id/item/list',
    component: AssetCheckItemList,
    title: '资产盘点结果',
    exact: true,
    hideSidebar: true,
    menuBelong: '/assets/list',
  },
  {
    path: '/assets/download',
    component: AssetQRCodeDownload,
    title: '资产二维码下载',
    exact: true,
    hideSidebar: true,
    menuBelong: '/assets/list',
  },
]
