import React from 'react'
import { Link } from 'react-router-dom'
import Avatar from '../components/Avatar'
import Icon from '../components/base/Icon'
import { logout } from '../../api/common'
import { getEnvByEnv, NODE_ENV_TYPE, getLocalStorage } from '../../utils'
import useLogoHeight from '../../hooks/useLogoHeight'
import useModuleMessage from '../../hooks/useModuleMessage'

// import { useGlobalContext } from '../context/GlobalContext'
// import useUnReadMessageCount from '../hooks/useUnReadMessageCount'

import commonEnv from '../../constants/env'
import { useGlobalContext } from '../context/GlobalContext'

const { REACT_APP_LOGO_WHITE, REACT_APP_HEADER_BG } = commonEnv

const { NODE_ENV } = process.env

const isDev = NODE_ENV === 'development'

const ENV_MAP = {
  [NODE_ENV_TYPE.DEVELOPMENT]: 'dev',
  [NODE_ENV_TYPE.TEST]: 'test',
}

function Header() {
  const { currentUser } = useGlobalContext()
  const { allUnReadCount, hasMessageModule } = useModuleMessage()
  // const { unReadMessageCount } = useGlobalContext()
  const height = useLogoHeight(REACT_APP_LOGO_WHITE, 50)

  // useUnReadMessageCount()

  const doLogout = async () => {
    await logout()
    const originalUrl = encodeURIComponent(window.location.pathname + window.location.search)
    window.location.replace(`/login?originalUrl=${originalUrl}`)
  }

  React.useEffect(() => {
    let timer
    // 开发环境或者本地server环境(有端口号)不嗅探token
    if (!isDev && !window.location.port) {
      timer = setInterval(() => {
        const value = getLocalStorage('token')
        if (!value) {
          doLogout()
        }
      }, 5000)
    }

    return () => {
      clearInterval(timer)
    }
  }, [])

  const renderIcon = () => {
    const env = getEnvByEnv()
    if (!Object.keys(ENV_MAP).includes(env)) {
      return null
    }
    return <span className={`env ${ENV_MAP[env]}`}>{ENV_MAP[env]}</span>
  }

  const style = REACT_APP_HEADER_BG
    ? { backgroundImage: `url("${REACT_APP_HEADER_BG}")` }
    : undefined

  return (
    <header className="top-header" style={style}>
      <div className="container">
        <Link to="/" className="logo-path only-img">
          <img className="logo-img" style={{ height }} src={REACT_APP_LOGO_WHITE} alt="" />
          {renderIcon()}
        </Link>
        <div className="toolbar">
          <div className="tools">
            {hasMessageModule ? (
              <a
                href="/deeppaas/message"
                target="_blank"
                className={allUnReadCount > 0 ? 'has-unread' : ''}
              >
                <Icon icon="ring" />
              </a>
            ) : null}
            {
              // <a
              //   href="/uc/message"
              //   target="_blank"
              //   className={unReadMessageCount > 0 ? 'has-unread' : ''}
              // >
              //   <Icon icon="ring" />
              // </a>
            }
          </div>
          <div className="user-ctrl">
            {currentUser.userName}&emsp;
            <Avatar url={currentUser.photoUrl} ali />
            <ul className="header-dropdown">
              <li>
                <Link to="/setting/user">
                  <Icon icon="home-user-center" />
                  &nbsp;&nbsp;个人中心
                </Link>
              </li>
              <li>
                <a onClick={doLogout}>
                  <Icon icon="home-back" />
                  &nbsp;&nbsp;退出
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
