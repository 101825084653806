import { RouteConfig } from 'react-router-config'
import AsyncComponent from './AsyncComponent'

import userCenter from './uc'
import setting from './setting'
import workflow from './workflow'
import application from './application'
import filesystem from './filesystem'
import meeting from './meeting'
import feedback from './feedback'
import asset from './asset'
import notice from './notice'
import customization from './customization'
import visio from './visio'
import kpi from './kpi'
import regulation from './regulation'

const routes: RouteConfig[] = [
  {
    path: '/',
    exact: true,
    component: AsyncComponent(() => import(/* webpackChunkName: 'home' */ '../@home')),
    title: 'Dashboard',
  },
  {
    path: '/login',
    exact: true,
    component: AsyncComponent(() => import(/* webpackChunkName: 'login' */ '../@login')),
    title: '登录',
  },
  {
    path: '/403',
    exact: true,
    component: AsyncComponent(() => import(/* webpackChunkName: '403' */ '../@403')),
    title: '无权限',
  },
  {
    path: '/fetch',
    exact: true,
    component: AsyncComponent(() => import(/* webpackChunkName: 'fetch' */ '../@example/Fetch')),
    title: 'Fetch',
  },
]

if (process.env.NODE_ENV === 'development') {
  routes.push({
    path: '/example',
    exact: true,
    component: AsyncComponent(() => import('../@example')),
    title: '试验区',
  })
}

export default [
  ...routes,
  ...userCenter,
  ...setting,
  ...workflow,
  ...application,
  ...filesystem,
  ...meeting,
  ...feedback,
  ...asset,
  ...notice,
  ...customization,
  ...visio,
  ...kpi,
  ...regulation,
  {
    path: '/*',
    component: AsyncComponent(() => import(/* webpackChunkName: '404' */ '../@404')),
    title: '404',
  },
].filter(Boolean) as RouteConfig[]
