import AsyncComponent from './AsyncComponent'

export default [
  {
    path: '/notice',
    component: AsyncComponent(() => import(/* webpackChunkName: 'notice-list' */ '../@notice')),
    title: '公告列表',
    exact: true,
    menuBelong: '/notice',
  },
  {
    path: '/notice/new',
    component: AsyncComponent(() =>
      import(/* webpackChunkName: 'notice-create' */ '../@notice/Detail')
    ),
    title: '新建公告',
    isCreate: true,
    exact: true,
    menuBelong: '/notice',
  },
  {
    path: '/notice/:id(\\d+)',
    component: AsyncComponent(() =>
      import(/* webpackChunkName: 'notice-detail' */ '../@notice/Detail')
    ),
    title: '公告详情',
    exact: true,
    menuBelong: '/notice',
  },
]
