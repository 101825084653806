import AsyncComponent from './AsyncComponent'

const DataApply = AsyncComponent(() =>
  import(/* webpackChunkName: 'data-apply' */ '../@application/DataApply')
)
const FlowApply = AsyncComponent(() =>
  import(/* webpackChunkName: 'flow-apply' */ '../@application/FlowApply')
)
const ForceEditApply = AsyncComponent(() =>
  import(/* webpackChunkName: 'force-edit-apply' */ '../@application/ForceEditApply')
)
const ForceList = AsyncComponent(() =>
  import(/* webpackChunkName: 'force-list' */ '../@application/ForceList')
)
const OrderPage = AsyncComponent(() =>
  import(/* webpackChunkName: 'order-page' */ '../@application/OrderPage')
)

const FileLog = AsyncComponent(() =>
  import(/* webpackChunkName: 'file-log' */ '../@application/FileLog')
)

export default [
  {
    path: '/application/data/:tableId(\\d+)/new',
    component: DataApply,
    title: '数据清单',
    exact: true,
    hideSidebar: true,
    fixHeader: true,
    noBread: true,
  },
  {
    path: '/application/data/:tableId(\\d+)/:orderId(\\d+)',
    component: DataApply,
    title: '数据清单',
    exact: true,
    hideSidebar: true,
    fixHeader: true,
    noBread: true,
  },
  {
    path: '/application/flow/task/:orderId(\\d+)',
    component: FlowApply,
    title: '流程审批',
    exact: true,
    hideSidebar: true,
    fixHeader: true,
    noBread: true,
  },
  {
    path: '/application/flow/:tableId(\\d+)/new',
    component: FlowApply,
    title: '填写申请单',
    exact: true,
    isNew: true,
    hideSidebar: true,
    fixHeader: true,
    noBread: true,
  },
  {
    path: '/application/flowlist/:tableId(\\d+)',
    component: OrderPage,
    title: '申请单查询',
    exact: true,
  },
  {
    path: '/application/datalist/:tableId(\\d+)',
    component: OrderPage,
    title: '申请单查询',
    exact: true,
  },
  {
    path: '/application/filelog/:tableId(\\d+)',
    component: FileLog,
    title: '附件记录',
    exact: true,
  },
  {
    // flow | data
    path: '/application/force/:type/:orderId(\\d+)',
    component: ForceEditApply,
    title: '更新表单',
    exact: true,
    hideSidebar: true,
    noBread: true,
    menuBelong: '/application/force',
  },
  {
    path: '/application/force',
    component: ForceList,
    title: '表单列表',
    exact: true,
    menuBelong: '/application/force',
  },
]
