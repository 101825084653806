import React from 'react'

export const ACTION_TYPES = {
  UPDATE_CURRENT_USER: '@common/UPDATE_CURRENT_USER',
  UPDATE_CURRENT_USER_RIGHTS: '@common/UPDATE_CURRENT_USER_RIGHTS',
  UPDATE_CURRENT_USER_MENU: '@common/UPDATE_CURRENT_USER_MENU',
  UPDATE_CURRENT_USER_MENU_AUTH: '@common/UPDATE_CURRENT_USER_MENU_AUTH',
  UPDATE_CURRENT_USER_WORDS: '@common/UPDATE_CURRENT_USER_WORDS',
}

export type CommonState = {
  currentUser?: UserVO
  userRights: string[]
  userMenu: string[]
  userMenuAuth?: Record<any, any>
  userWords: string[]
}

function commonReducer(state: CommonState, action: { type: string; payload: any }): CommonState {
  const { type, payload } = action
  switch (type) {
    case ACTION_TYPES.UPDATE_CURRENT_USER:
      return {
        ...state,
        currentUser: payload,
      }
    case ACTION_TYPES.UPDATE_CURRENT_USER_RIGHTS:
      return {
        ...state,
        userRights: payload,
      }
    case ACTION_TYPES.UPDATE_CURRENT_USER_MENU:
      return {
        ...state,
        userMenu: payload,
      }
    case ACTION_TYPES.UPDATE_CURRENT_USER_MENU_AUTH:
      return {
        ...state,
        userMenuAuth: payload,
      }
    case ACTION_TYPES.UPDATE_CURRENT_USER_WORDS:
      return {
        ...state,
        userWords: payload,
      }
    default:
      // eslint-disable-next-line no-console
      console.log(`Unhandled action type: ${type}`)
      return state
  }
}

export default function useCommonContext(initialCommonState: Partial<CommonState>) {
  return React.useReducer(commonReducer, {
    currentUser: undefined,
    userRights: [],
    userMenu: [],
    userMenuAuth: undefined,
    userWords: [],
    ...initialCommonState,
  })
}
