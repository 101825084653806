import React, { CSSProperties } from 'react'
import classNames from 'classnames'

import commonEnv from '../../constants/env'

const { REACT_APP_AVATAR_PREFIX: PREFIX, REACT_APP_DEFAULT_AVATAR } = commonEnv

type AvatarProps = {
  url?: string
  ali?: boolean
  size?: number
  style?: CSSProperties
  className?: string
  title?: string
}

export default function Avatar(props: AvatarProps) {
  let url = props.url || REACT_APP_DEFAULT_AVATAR
  if (props.url && props.ali) {
    url = `${PREFIX + url}?x-oss-process=image/resize,w_100,h_100`
  }

  const style = { backgroundImage: `url(${url})`, ...(props.style || {}) }
  if (props.size) {
    style.width = `${props.size}px`
    style.height = `${props.size}px`
  }
  return (
    <div
      style={style}
      className={classNames('avatar', props.className, { 'show-title': props.title })}
      data-title={props.title}
    >
      <img src={url} alt="avatar" />
    </div>
  )
}
