import axios, { AxiosResponse } from 'axios'

import { setLocalStorage } from '../utils'
import { catchUnauthorized, XMLConfig } from './utils'

export const ERROR_CODE = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  INTERNAL_SERVER_ERROR: 500,
  BUSINESS_ERROR: 409,
}

const ignoreUrls = ['/oaOrder/flow/']

const instance = axios.create({
  baseURL: '/api',
  ...XMLConfig,
})

function requestError(error: any) {
  // eslint-disable-next-line no-console
  console.log(error)
  return Promise.reject(error)
}

function afterReceiveResponse(response: AxiosResponse<any>) {
  const { data } = response
  const { errorCode } = data

  if (errorCode && errorCode > 0) {
    // eslint-disable-next-line no-console
    console.log('Response Data')
    // eslint-disable-next-line no-console
    console.log(data)
    const apiUrl = response.config.url
    const responseDoNotToast = ignoreUrls.some(u => apiUrl!.indexOf(u) > -1)

    if (errorCode === ERROR_CODE.UNAUTHORIZED) {
      return catchUnauthorized(response)
    }

    if (errorCode >= ERROR_CODE.INTERNAL_SERVER_ERROR) {
      ;(window as any).message('系统出错，请重试或联系系统管理员')
    } else if (errorCode === ERROR_CODE.FORBIDDEN && !responseDoNotToast) {
      ;(window as any).message(data.message || '您没有权限执行该操作')
    } else if (errorCode !== 404) {
      ;(window as any).message(data.message)
    }

    return Promise.reject(response)
  }

  // websocket连接需要用户token
  setLocalStorage('token', response.headers.at)

  return response
}

instance.interceptors.response.use(afterReceiveResponse, requestError)

export default instance

export function extractData<T>(response: AxiosResponse<R<T>>) {
  const { data } = response.data as R<T>
  return data
}

export function extractResponse<T, P extends any[] = any[]>(
  requestFn: (...args: P) => Promise<AxiosResponse<R<T>>>
) {
  return (...args: P) => requestFn(...args).then(response => extractData(response))
}

export function splitPageData<T = any>(data?: PageData<T>): [T[], PageInfo] {
  if (!data) {
    return [[], { pageNo: 1, pageSize: 10, sort: '', total: 0, totalPages: 0 }]
  }
  const { list, ...pageInfo } = data
  // 可能list是null
  return [list || [], pageInfo]
}
