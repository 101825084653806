import React from 'react'
import { getImageSize } from '../utils'

// const LOGO_LIST = [
//   '//static.linghemedia.com.cn/logo/linghe-logo-blue.png',
//   '//linghe-test-static.oss-cn-beijing.aliyuncs.com/jiuxiao/logo.png',
//   '//assets.deeppaas.com/logo/deeppaas/logo-vertical.png?x-oss-process=image/resize,w_200',
//   '//oss-partner-public.linghe.tv/kubao/logo-color.png',
// ]

function getLogoHeight(data: { width: number; height: number }, height: number) {
  const ratio = Number((data.height / data.width).toFixed(2))
  if (ratio >= 0.5) {
    return height
  }
  if (ratio >= 0.25 && ratio < 0.5) {
    return height * (1.2 - ratio)
  }
  return height * (1 - ratio)
}

function useLogoHeight(url: string | undefined, planHeigth: number) {
  const [height, setHeight] = React.useState(0)
  React.useEffect(() => {
    getImageSize(url, data => {
      const h = getLogoHeight(data, planHeigth)
      setHeight(h)
    })
  }, [planHeigth, url])

  return height
}

export default useLogoHeight
